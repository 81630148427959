import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"

import styles from "./showcaseButtons.module.scss"

const ShowcaseButtons = ({ categories }) => (
  <Reveal effect="fadeInDown">
    <div className={styles.buttons}>
      <Link className={cx("btn-primary", styles.ghost)} activeClassName={styles.active} to={`/showcase`}>
        All
      </Link>
      {categories.map(cat => (
        <Link
          key={cat.title}
          className={cx("btn-primary", styles.ghost)}
          activeClassName={styles.active}
          to={`/showcase/${cat.slug.current}`}
        >
          {cat.title}
        </Link>
      ))}
    </div>
  </Reveal>
)

export default ShowcaseButtons
