import React from "react"
import cx from "classnames"
import Reveal from "react-reveal/Reveal"

import * as Modal from "../modal/modal"
import Wrapper from "../layout/wrapper/wrapper"
import VideoEmbed from "../videoEmbed/videoEmbed"
import BlockContent from "../blockContent/blockContent"
import Image from "../image/image"

import styles from "./showcaseItems.module.scss"

const ShowcaseItems = ({ items }) => (
  <Wrapper reveal={false}>
    <div className="grid fullgap">
      {items.map(item => (
        <div
          id={item.slug?.current}
          key={item._key}
          className={cx(item.featured ? "gridItem small-12 medium-8" : "gridItem small-12 medium-4", styles.item)}
        >
          <Modal.Wizard>
            <Reveal effect="fadeInDown">
              {/* img trigger */}
              {(item.action?.length && item.image._rawAsset) || (item.description?.length && item.image._rawAsset) ? (
                <Modal.Trigger>
                  <Image image={item.image._rawAsset} alt={item.image.alt} />
                </Modal.Trigger>
              ) : (
                <Image image={item.image._rawAsset} alt={item.image.alt} />
              )}

              <h2 className={styles.title}>{item.title}</h2>
              <h3 className={styles.category}>{item.category?.title}</h3>
              <p>{item.text}</p>
              {item.action?.length || item.description?.length ? (
                <>
                  <Modal.Trigger>
                    <a href="#" className="link-pink">
                      Find out more <span className="visually-hidden">about {item.title}</span>
                    </a>
                  </Modal.Trigger>
                  <Modal.Content>
                    {item.action.length && item.action[0]._type === "videoEmbed" ? (
                      <VideoEmbed url={item.action[0].url} instilled={item.action[0].instilled} noPadding />
                    ) : null}
                    {item.description ? (
                      <div className="grid">
                        <div className="gridItem small-12 medium-10 medium-offset-1 large-8 large-offset-2">
                          <BlockContent blocks={item.description} />
                        </div>
                      </div>
                    ) : null}
                  </Modal.Content>
                </>
              ) : null}
            </Reveal>
          </Modal.Wizard>
        </div>
      ))}
    </div>
  </Wrapper>
)

export default ShowcaseItems
